/*******************************************************************************/
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 2, 2021 */
/* This font is to be used instead of "Neuzeit"  mentioned in the design files */
/*******************************************************************************/

@font-face {
  font-family: "OpenSansBold";
  src: url("./fonts/OpenSansBold.woff2") format("woff2"),
    url("./fonts/OpenSansBold.woff") format("woff"),
    url("./fonts/OpenSansBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSansRegular";
  src: url("./fonts/OpenSansRegular.woff2") format("woff2"),
    url("./fonts/OpenSansRegular.woff") format("woff"),
    url("./fonts/OpenSansRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/******************************************************************/
@font-face {
  font-family: "ElgocAltMedium";
  src: url("./fonts/ElgocAlt-Medium.woff2") format("woff2"),
    url("./fonts/ElgocAlt-Medium.woff") format("woff"),
    url("./fonts/ElgocAlt-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ElgocAltThin";
  src: url("./fonts/ElgocAlt-Thin.woff2") format("woff2"),
    url("./fonts/ElgocAlt-Thin.woff") format("woff"),
    url("./fonts/ElgocAlt-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

/******************************************************************/
html,
body {
  background-color: #dcdad4;
  /*overflow: hidden;*/ /*pari what abt mobile for screen Elaborate*/
  overscroll-behavior-x: none;
  height: 100%;
  margin: 0;
  font-family: "OpenSansRegular", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
  /*-webkit-appearance: none;*/
}

.hidden {
  overflow: hidden;
}

#root {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
}
