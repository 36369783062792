/*Light grey background #dcdad4 Dark grey text and icons  */

p {
  margin: 0;
  padding: 0;
  color: #282828;
}

/*.container {
  padding-top: 90px;
  padding-bottom: 50px;
}*/

.container {
  padding-top: 0px;
  padding-bottom: 0px;
}

.mobile-container {
  height: 100%;
  width: 100%;
  background-color: #dcdad4;
}

/*.wrapper {
  margin: 0 auto;
  max-width: 900px;
  min-width: 900px;
  height: 550px;
  background-color: #dcdad4;
  border-radius: 2px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
}*/

.wrapper {
  margin: auto auto;
  max-width: 1200px;
  min-width: 900px;
  min-height: 550px;
  height: 100vh;
  background-color: #dcdad4;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.column-centered {
  display: "flex";
  flex-direction: "column";
  justify-content: center;
  align-items: center;
  text-align: center;
}

.paragraph {
  font-size: 1.2rem;
  /*  line-height: 2.2rem;
  word-spacing: 0.15rem;*/
}

.word-paragraph {
  font-size: 1.2rem;
  font-family: "ElgocAltMedium";
}

/*********************************************************************/
/*********************************************************************/
/*********************************************************************/
.row-centered {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.center-in-a-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.row-centered-end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.no-decoration {
  text-decoration: none;
  color: black;
}

.single-end-of-row {
  display: flex;
  justify-content: flex-end;
}

.line {
  height: 0.5px;
  background-color: black;
}

.rotated {
  transform-origin: 100% 0;
  transform: translateX(-100%) rotate(-90deg);
}

.left-gapped {
  margin-left: 150px;
}

/***************************/

.spaced {
  justify-content: space-between;
}

/*****/
.page {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-leave {
  opacity: 1;
  transform: scale(1);
}

.page-leave-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 100ms;
}

/**********************************/

/* ENTER TRANSITION */
/* Declare transition start properties*/
.slide-enter {
  transform: translateY(100vw);
  opacity: 0;
  position: absolute;
}

/* Declare transition properties */
.slide-enter.slide-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 1500ms linear 1500ms;
}

/* EXIT TRANSITION */
.slide-exit {
  transform: translateY(0);
  opacity: 1;
}

.slide-exit.slide-exit-active {
  transform: translateY(-100vw);
  opacity: 0;
  transition: all 500ms linear;
}

/*Pari: all font should have fallbacks???*/
/***********************************************************************************/
/******************************** text fonts & sizes *******************************/
/***********************************************************************************/

/****************************************** text ************************************/
.text-xs {
  font-size: 0.9rem;
  font-family: "OpenSansRegular";
}

.text-s {
  font-size: 0.9rem;
  font-family: "OpenSansRegular";
}

.text-m {
  font-size: 1.2rem;
  font-family: "OpenSansRegular";
}

.text {
  font-size: 1rem;
  font-family: "OpenSansRegular";
}

.text-xl {
  font-size: 1.5rem;
  font-family: "OpenSansRegular";
}

/****************************************** small-text ************************************/
.small-text-xs {
  font-size: 0.6rem;
  font-family: "OpenSansRegular";
}

.small-text-s {
  font-size: 0.7rem;
  font-family: "OpenSansRegular";
}

.small-text-m {
  font-size: 0.8rem;
  font-family: "OpenSansRegular";
}

.small-text {
  font-size: 0.8rem;
  font-family: "OpenSansRegular";
}

.small-text-xl {
  font-size: 1rem;
  font-family: "OpenSansRegular";
}

/****************************************** bold-small-text ************************************/
.bold-small-text-xs {
  font-size: 0.6rem;
  font-family: "OpenSansBold";
}

.bold-small-text-s {
  font-size: 0.7rem;
  font-family: "OpenSansBold";
}

.bold-small-text-m {
  font-size: 0.8rem;
  font-family: "OpenSansBold";
}

.bold-small-text {
  font-size: 0.8rem;
  font-family: "OpenSansBold";
}

.bold-small-text-xl {
  font-size: 1rem;
  font-family: "OpenSansBold";
}

/****************************************** large-text ************************************/
.large-text-xs {
  font-family: "OpenSansRegular";
  font-size: 0.8rem;
}

.large-text-s {
  font-family: "OpenSansRegular";
  font-size: 0.8rem;
}

.large-text-m {
  font-family: "OpenSansRegular";
  font-size: 1.5rem;
}

.large-text {
  font-family: "OpenSansRegular";
  font-size: 1.5rem;
}

.large-text-xl {
  font-family: "OpenSansRegular";
  font-size: 1.8rem;
}

/****************************************** bold-text ************************************/
.bold-text-xs {
  font-size: 0.9rem;
  font-family: "OpenSansBold";
}

.bold-text-s {
  font-size: 0.9rem;
  font-family: "OpenSansBold";
}

.bold-text-m {
  font-size: 1.2rem;
  font-family: "OpenSansBold";
}

.bold-text {
  font-size: 1rem;
  font-family: "OpenSansBold";
}

.bold-text-xl {
  font-size: 1.5rem;
  font-family: "OpenSansBold";
}

/****************************************** label ************************************/
.label-xs {
  font-size: 0.9rem;
  font-family: "OpenSansRegular";
}

.label-s {
  font-size: 0.9rem;
  font-family: "OpenSansRegular";
}

.label-m {
  font-size: 1.2rem;
  font-family: "OpenSansRegular";
}

.label {
  font-size: 1.2rem;
  font-family: "OpenSansRegular";
}

.label-xl {
  font-size: 1.5rem;
  font-family: "OpenSansRegular";
}

/****************************************** bold-label ************************************/
.bold-label-xs {
  font-size: 0.9rem;
  font-family: "OpenSansBold";
}

.bold-label-s {
  font-size: 0.9rem;
  font-family: "OpenSansBold";
}

.bold-label-m {
  font-size: 1.2rem;
  font-family: "OpenSansBold";
}

.bold-label {
  font-size: 1.2rem;
  font-family: "OpenSansBold";
}

.bold-label-xl {
  font-size: 1.5rem;
  font-family: "OpenSansBold";
}

/****************************************** tags ************************************/
.tags-xs {
  font-size: 0.8rem;
  font-family: "OpenSansRegular";
}

.tags-s {
  font-size: 0.9rem;
  font-family: "OpenSansRegular";
}

.tags-m {
  font-size: 1rem;
  font-family: "OpenSansRegular";
}

.tags {
  font-size: 1rem;
  font-family: "OpenSansRegular";
}

.tags-xl {
  font-size: 1.3rem;
  font-family: "OpenSansRegular";
}

/****************************************** title ************************************/
.title-xs {
  font-family: "ElgocAltMedium";
  font-size: 1.2rem;
}

.title-s {
  font-family: "ElgocAltMedium";
  font-size: 1.6rem;
}

.title-m {
  font-family: "ElgocAltMedium";
  font-size: 2.5rem;
}

.title {
  font-family: "ElgocAltMedium";
  font-size: 2.5rem;
}

.title-xl {
  font-family: "ElgocAltMedium";
  font-size: 2.1rem;
}

/****************************************** small-title ************************************/
.small-title-xs {
  font-family: "ElgocAltMedium";
  font-size: 1rem;
}

.small-title-s {
  font-family: "ElgocAltMedium";
  font-size: 1rem;
}

.small-title-m {
  font-family: "ElgocAltMedium";
  font-size: 1.3rem;
}

.small-title {
  font-family: "ElgocAltMedium";
  font-size: 1.3rem;
}

.small-title-xl {
  font-family: "ElgocAltMedium";
  font-size: 1.6rem;
}

/****************************************** large-title ************************************/
.large-title-xs {
  font-family: "ElgocAltMedium";
  font-size: 1.2rem;
}

.large-title-s {
  font-family: "ElgocAltMedium";
  font-size: 1.5rem;
}

.large-title-m {
  font-family: "ElgocAltMedium";
  font-size: 1.8rem;
}

.large-title {
  font-family: "ElgocAltMedium";
  font-size: 1.8rem;
}

.large-title-xl {
  font-family: "ElgocAltMedium";
  font-size: 2.1rem;
}

/****************************************** very-large-title ************************************/
.very-large-title-xs {
  font-family: "ElgocAltMedium";
  font-size: 2rem;
}

.very-large-title-s {
  font-family: "ElgocAltMedium";
  font-size: 2.8rem;
}

.very-large-title-m {
  font-family: "ElgocAltMedium";
  font-size: 5rem;
}

.very-large-title {
  font-family: "ElgocAltMedium";
  font-size: 5rem;
}

.very-large-title-xl {
  font-family: "ElgocAltMedium";
  font-size: 5rem;
}

/****************************************** huge-title ************************************/
.huge-title-xs {
  font-family: "ElgocAltMedium";
  font-size: 3rem;
}

.huge-title-s {
  font-family: "ElgocAltMedium";
  font-size: 4rem;
}

.huge-title-m {
  font-family: "ElgocAltMedium";
  font-size: 10rem;
}

.huge-title {
  font-family: "ElgocAltMedium";
  font-size: 10rem;
}

.huge-title-xl {
  font-family: "ElgocAltMedium";
  font-size: 12rem;
}


/* add pointer cursor to start screen button (also curently yellow outlione to help with debug)*/
.row .centered-content {
  cursor: pointer;
}

/* inverted colours for ticket screens */
.ticketScreen{
  background-color: #282828;
}

.ticketScreen p {
  color: #dcdad4;
}
#TicketTag p{
  color: #282828;
}

.skeletonLoader{
  animation: SkeletonLoading 1s linear infinite alternate;
}

@keyframes SkeletonLoading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

#disclaimerButton{
  background-color: rgb(58, 59, 60);
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  margin-right: 10px;
  cursor: pointer;

  color: #dcdad4;
  font-family: "OpenSansRegular", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: medium;
}

.videoContainer{
  border: solid #282828;
}
.videoContainer img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}


/*-----------------ticket share button---------------*/


#socialDropDown{
  box-sizing: border-box;
  width: 100%; 
  height: 0px;
  transition: all .25s ease;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  background-color: #dcdad4;
  border-radius: 0.8rem;
  z-index: 10;
  margin-left: 10px;
}

.socialDropDownHead{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 0 1rem;
}

.socialDropDownHead img {
  width: 1.5rem;
  height: 1.5rem;
  transform: translateY(-0.5rem);
}

.socialDropDownLinks{
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.socialDropDownLinksText{
  text-decoration: none;
  font-size: 13;
  font-weight: bold;
  text-align: Center;
  margin: auto 0.5rem;
}

.socialDropDownLinksText p{
  color: #282828;
  margin-top: -.5rem;
}

.socialDropDownLinks img{
  width: 3rem;
  height: 3rem;
  margin: 0.5rem;
}

.myTicketOuter{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}


.myTicketButton{
  margin-top: 0.5rem;
  background-color: #282828;
  color: #dcdad4;
  border: solid #282828;
  border-radius: 0.2rem;
  padding: 0.2rem 0.5rem;
}

.myTicketButton a{
  color: #dcdad4;
}

.myTicketDivider{
  margin: 1rem 0;
  width: 100%;
  border: solid 1px #282828;
  background-color: #282828;
}

.LibrarysLocal{
  margin:1rem;
  padding: 0.2rem;
}


#LibraryModal{
  display: none;
  width: 100%;
  height: 100%;
  margin: auto;
  z-index: 50;
  position: absolute;
  color: #dcdad4;
  justify-content: center;
  align-items: center;
}

#modalWindow{
  width: 40%;
  border: #282828 solid;
  border-radius: 1rem;
  background-color: #282828;
  color: #dcdad4;
  padding: 2rem;
  /*opacity: 100%;*/
}

#modalWindow p{
  font-family: "ElgocAltMedium";
  color: #dcdad4;
  font-size: 120%;
}

#LibraryModaltitle{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#LibraryModaltitle p{
  font-family: "ElgocAltMedium";
  font-size: 3rem;
  color: #dcdad4;
}

#bookDetails{
  line-height: 160%;
}

#LibraryModalcloseText{
  width: 30%;
  font-weight: bold;
  margin: auto;
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  background-color: #dcdad4;
  color: #282828;
  padding: .5rem;
  border-radius: .2rem;

}

.userTicketBottomButtons{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 25vw;
  margin: 0 0.5rem;
}

.introLogoRow{
  background-color: #FFFFFF;
  position: absolute;
  bottom: 0; 
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;

  width: 100vw;
  left: calc(-50vw + 50%);
}

.introLogoRow img{
  box-sizing: border-box;
  padding: .5rem;
  max-width: calc( 100% / 10 );
  min-width: calc( 100% / 10 );
  min-height: 100%;
  min-height: 100%;
  object-fit: contain;
  
}

#thanksButtonRow{
  display: flex;
  justify-content: space-between;
}

.ThanksButton{
  box-sizing: border-box;
  width: 100%;
  background-color: rgb(58, 59, 60);
  border: none;
  border-radius: 0.3rem;
  padding: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: #dcdad4;
  font-family: "OpenSansRegular", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: medium;
}

.ThanksButton summary{
  max-height: 2rem;
  text-align: center;
}

.ThanksButton > summary {
  list-style: none;
}

.ThanksButton > summary::-webkit-details-marker {
  display: none;
}


.ThanksButton p{
  color: #dcdad4;
}

#chartOuter{
  animation-name: example;
  animation-duration: 1s
}

@keyframes example {
  from {width: 100%;}
  to {width: 99%;}
}

#UploadLoader {
  display: none;
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #282828;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.CreateTicektPageHeader{
  width: 90%;
  margin: auto;
  margin-bottom: 3rem;
}

.CreateTicektPageHeader .title{
  margin-top: 5rem;
  font-family: "ElgocAltMedium";
  font-size: 7rem;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 1000px) {
  .CreateTicektPageHeader .title{
    margin-top: 3rem;
    font-size: 5rem;
    line-height: 5rem;
  }
}

@media only screen and (max-width: 600px) {
  .CreateTicektPageHeader .title{
    margin-top: 0.5rem;
    font-size: 3rem;
    line-height: 3rem;
  }
}


#uploadOuter{
  margin-top: 5rem;
  width: 90%;
  margin: auto;

  display: flex;
}

@media only screen and (max-width: 1000px) {
  #uploadOuter{
    flex-wrap: wrap;
    justify-content: center;
  }

  #uploadLeft, #uploadRight {
    width: 80%;
  }

  #uploadLeft form {
    width: 100%;
  }
}

#uploadLeft {
  width: 50%;
}

@media only screen and (max-width: 600px) {
  #uploadLeft {
    width: 100%;
  }
}

#uploadTagsContainer{
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

#uploadTagstext{
  width: 100%;
  text-align: center;
  margin: 1rem auto 0.5rem auto;

}

#uploadinputsContainer select{
  background-color: #dcdad4;
  border: solid 2px #282828;
  padding: 0.3rem;
  border-radius: 0.3rem;
}

#uploadLeft button, #file-upload-button {
  color: #dcdad4;
  background-color: #282828;
  padding: 0.2rem;
  border-radius: 0.3rem;
  border: solid #282828;
  margin: auto;
}


/*unslplash search button*/
#unsplash-search > label > button {
  color: #dcdad4;
  background-color: #282828;
  border-radius: 0.2rem;
  border: solid #282828;
  margin-left: 1rem;
  height: 1.2rem;
  padding: 0 1rem;
}

/*unslplash search text input */
#unsplash-search > label > input.input {
  background-color: rgb(244, 244, 242);
  border-radius: 0.2rem;
  border: solid 2px #282828;
  height: 1.2rem;
  margin-left: 1rem;
}


#uploadRight{
  margin-left: 2rem;
  width: 50%;
}

@media only screen and (max-width: 600px) {
  #uploadRight {
    margin: 1rem 0;
    width: 100%;
    margin-left: 0;
  }
}


#uploadOuter form{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: rgb(244, 244, 242);
  border-radius: 0.3rem;
  padding: 1rem;
}

@media only screen and (max-width: 600px) {
  #uploadOuter form {
    width: calc(100% - 2rem);
    padding: 1rem;
    margin-top: 0;
  }
}

#uploadinputsContainer{
  display: flex;
  flex-wrap: wrap;
}

#TicketImagePreview{
  min-width: 200px;
  min-height: 200px;
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
  overflow: hidden;
}

#uploadImageContainer{
  box-sizing: border-box;
  justify-content: flex-start;
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}

#uploadTextContainer{
  box-sizing: border-box;
  justify-content: flex-end;
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 500px) {
  #uploadTextContainer{
    width: 100%;
  }

  #uploadinputsContainer{
    justify-content: center;
  }

  #uploadImageContainer{
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

#uploadTextContainer input, #uploadTextContainer textarea{
  font-family: "ElgocAltMedium";
  font-size: 1.2rem;
  margin: 0.5rem;
  resize: none;
  width: 100%;
  background-color: #dcdad4;
  border: dotted 2px #282828;
  border-radius: 0.3rem;
  padding: 0.1rem;
}

#uploadTextContainer textarea{
  font-family: "OpenSansRegular";
  font-size: 0.8rem;
}

#charsLeft{
  font-size: 0.8rem;
  margin-right: 0.5rem;
}

#TicketImagePreview{
  background-color: #282828;
  border-radius: 0.3rem;
}

#UnsplashSearchContainer{
  width: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  #unsplash-search {
    margin: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  #unsplash-search > label{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  #unsplash-search > label > *{
    margin: auto;
    margin-left: auto!important;
    margin-top: 0.5rem;
  }

  #unsplash-search > label > input.input {
    width: 100%;
    height: 1.5rem;
    font-size: 1rem;
    margin-left: 0rem;
  }

  #unsplash-search > label > input[type=submit]:nth-child(2){
    height: 1.6rem;
    font-size: 1rem;
    margin-left: 0rem;
  }
}

#unsplash-search{
  padding: 1rem 1rem 0.2rem 1rem;
}

#FileUploadInoutContainer{
  background-color: #dcdad4;
  width: 80%;
  padding: 1rem;
  border: dotted 2px #282828;
  border-radius: 0.3rem;
  margin: 1rem auto;
}

#FileUploadInoutContainer input{
  margin-top: 1rem;
}

  @media only screen and (max-width: 600px) {
    /*form submit button*/
    #uploadLeft > form > button{
      font-size: 1rem;
    }
  }

#progressBarOuter{
  opacity: 0;
  width: 100%;
  margin: 1rem auto;
  height: 0.5rem;
  border: solid 2px #282828;
  border-radius: 0.3rem;
}

#progressBar{
  height: 100%;
  width: 50%;
  background-color: #282828;
}



#UserTicketsOutput{
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (max-width: 1000px) {
  #UserTicketsOutput{
    width: 100%;
  }
}

.userticketCard{
  box-sizing: border-box;
  width: calc(25% - 2rem);
  margin: 1rem;
  background: rgb(244, 244, 242);
  font-family: "ElgocAltMedium";
  font-size: 1.2rem;
  border-radius: 0.3rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#cardnumbering{
  display: none;
  width: 100%;
  font-size: 1rem;
  text-align: center;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 1000px) {
  .userticketCard{
    width: calc(33% - 2rem);
  }
}

@media only screen and (max-width: 600px) {
  .userticketCard{
    width: calc(50% - 2rem);
  }
}

#userticketCardImgOuter{
  position: relative;
  width: 90%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 0.3rem;
}

#userticketCardAspectDummy{
  margin-top: 90%;
}

#userticketCardImg{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  overflow: hidden;
  border-radius: 0.3rem;
}

#userticketCardTitle{
  margin-top: 1rem;
  width: 90%;
  font-size: 1.6rem;
}

#userticketCardText{
  width: 90%;
  font-size: 1rem;
  font-family: "OpenSansRegular";
}

#userticketCardDate{
  text-align: end;
  width: 90%;
  font-size: 0.8rem;
  font-family: "OpenSansRegular";
}

.cardTags{
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 0.5rem;
}

.carTag1, .carTag2{
  text-align: center;background-color: #dcdad4;
  padding: 0.2rem 0.3rem 0.2rem 0.3rem;
  margin-bottom: 0.2rem;
  height: 1rem;
  border-radius: 0.3rem;

  margin-right: 0.2rem;
}



.userticketCard button{
  margin: 1rem auto;
  height: 2rem;
  border-radius: 0.2rem;
  padding: 0.5rem;
  color: rgb(244, 244, 242);
  background: rgb(40, 40, 40);
  border: none;
}



/*-----------------user ticket single screen ----------------------------*/

.ticketContentOuter{
  width: 60%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-self: center;
}


#ticketImg{
  box-sizing: border-box;
  min-width: 15rem;
  min-height: 15rem;
  max-width: 15rem;
  max-height: 15rem;
  object-fit: cover;
  overflow: hidden;
  margin: 1rem;
}

@media only screen and (max-width: 600px) {
  .ticketContentOuter{
    width: 90%;
    flex-wrap: wrap;
  }

  #ticketImg{
    justify-self: flex-end;
  }

  #textOuter{
    width: 90%;
  }
}

#ticketTitle{
  color: #dcdad4;
  font-family: "ElgocAltMedium";
  font-size: 2.5rem;
}

#ticketText{
  color: #dcdad4;
  font-size: 1rem;
  font-family: "OpenSansRegular";
}

#userTicketTextOuter{
  margin: 1rem;
}


/*--------------------------------------- unsplash list ----------------------------------*/

.photo-grid {
  width: 100%;
  overflow-x: scroll;
  display: flex;
  list-style-type: none;
}

.photo-grid::-webkit-scrollbar {
  width: 0.7em;
  border-radius: 0.2rem;
}
 
.photo-grid::-webkit-scrollbar-track {
  border-top: solid 2px #282828;
}
 
.photo-grid::-webkit-scrollbar-thumb {
  background-color: #282828;
  border-radius: 0.2rem;

}

.photo-grid li {
  height: 20vh;
  flex-grow: 1;
  margin: 0 8px 8px 0;
}

.photo-grid li:last-child {
  flex-grow: 10;
}

.photo-grid img {
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
  border-radius: 4px;
}

.HorzontalLine{
  margin-top: 2rem;
  width: 100%;
  height: 0.1rem;
  background-color: #282828;
}

.CreateTicektPageHeader .lowerTitle{
  margin-top: 2rem;
  font-family: "ElgocAltMedium";
  font-size: 7rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 1000px) {
  .CreateTicektPageHeader .lowerTitle{
    margin-top: 2rem;
    font-size: 5rem;
    line-height: 5rem;
  }
}

@media only screen and (max-width: 600px) {
  .CreateTicektPageHeader .lowerTitle{
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: 0.5rem!important;
  }
}

#BookInputContainer{
  width: 90%;
  padding: 1rem;
  border-radius: 0.3rem;
  margin: 1rem auto;
}

#BookInputContainer input {
  margin-top: 0.7rem;
  width: 100%;
  background-color: #dcdad4;
  border: dotted 2px #282828;
  padding: 0.5rem 0.3rem;
  border-radius: 0.3rem;
}

